import styled from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSDropdownButtonMobileStyle {
  export const Button = styled.button<{ isDark: boolean }>`
    font-family: "ReadexPro";
    width: 100% !important;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    justify-content: center;
    color: ${colors.light.light};
    font-size: 24px;
    line-height: 37.44px;
    font-weight: 700;
    text-align: center;
    justify-content: space-between;
  `;

  export const DropdownContent = styled.div<{
    width?: number | string;
    height?: number | string;
  }>`
    position: flex;
    min-width: 160px;
    width: 100%;
    height: auto;
    margin-top: 16px;
    z-index: 99999999;
    background: ${colors.shadesOfdark.dark08};
    border-radius: 8px;
    padding: 16px;
    justify-content: center;
    font-weight: 400;
  `;

  export const DropdownContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    gap: 16px;
  `;

  export const DropdownContentItemContainer = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) =>
      props.isActive ? colors.light.light : colors.shadesOflight.light64};
    font-size: 18px;
    line-height: 28.08px;
    font-size: 500;
    position: relative;
    @media (max-width: 1092px) {
      gap: 4px;
    }
  `;

  export const Menu = styled.a<{}>`
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: "ReadexPro";
    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.smallText1.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    color: ${colors.dark.dark};
    padding: 0px;
    height: 22px;
    width: 100%;
    border-radius: 12px;
    transition: background-color 0.5s, color 0.2s;
    &:hover {
      background-color: ${colors.neutrals.grey01};
      color: ${colors.dark.dark};
    }
  `;
}
