import { footerData, headerAnchorList } from "../../../util/headerFooterData";
import useDeviceType from "../../../util/isMobile";
import PositionDetailPageDesktop from "./DesktopView/PositionDetailPageDesktop";
import PositionDetailPageMobile from "./MobileView/PositionDetailPageMobile";

const positionDetailData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    logoClicked: () => {},
  };
};

function PositionDetailPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <PositionDetailPageMobile
        positionDetailData={positionDetailData(isMobile)}
      />
    );
  } else {
    return (
      <PositionDetailPageDesktop
        positionDetailData={positionDetailData(isMobile)}
      />
    );
  }
}

export default PositionDetailPage;
