import styled from "styled-components";

export namespace NSPositionDetailPageMobileStyle {
  export const Container = styled.div``;

  export const LeftContainer = styled.div`
    width: 100vw;
    position: relative;
    margin-bottom: 24px;
  `;

  export const RightContainer = styled.div<{ showMenu: boolean }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    transition: all 0.5s;
    z-index: 10000;
    right: ${({ showMenu }) => (showMenu ? "0px" : "-100vw")};
    border-radius: ${({ showMenu }) => (showMenu ? "0px" : "90% 0px 0px 90%")};
    overflow: hidden;
  `;
  export const StickyHelperContainer = styled.div`
    position: relative;
  `;

  export const MainContainer = styled.div`
    padding: 16px 16px 32px 16px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  `;

  export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `;
}
