import { useRef, useState } from "react";
import colors from "../../style/colors";
import { NSDropdownButtonMobileStyle } from "./DropdownButtonMobile.style";
import { NSDropdownButtonMobileType } from "./DropdownButtonMobile.types";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevronDown.svg";
import { ReactComponent as PrefixIcon } from "../../assets/icons/prefixIcon.svg";
import { ReactComponent as Layers } from "../../assets/icons/layers.svg";
import { ReactComponent as ResumeIcon } from "../../assets/icons/resumeIcon.svg";
import { ReactComponent as DoubleCheck } from "../../assets/icons/doubleCheck.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { useLocation } from "react-router-dom";

export const DropdownButtonMobile = ({
  children,
  onClick,
  isDark = false,
}: NSDropdownButtonMobileType.IDropdownButtonMobile) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const handleButtonClick = () => {
    onClick?.();
    setIsOpen(!isOpen);
  };

  const menuItems = [
    {
      href: "/dashboard/status",
      icon: <PrefixIcon stroke={colors.neutrals.grey04} />,
      label: "Güncel Durumum",
    },
    {
      href: "/position/list",
      icon: <Layers stroke={colors.neutrals.grey04} />,
      label: "Açık Pozisyonlar",
    },
    {
      href: "/dashboard/cv",
      icon: <ResumeIcon stroke={colors.neutrals.grey04} />,
      label: "Özgeçmişim",
    },
    {
      href: "/dashboard/applications",
      icon: (
        <DoubleCheck
          stroke={colors.neutrals.grey04}
          fill={colors.neutrals.grey04}
        />
      ),
      label: "Başvurularım",
    },
    {
      href: "/dashboard/account",
      icon: <User stroke={colors.neutrals.grey04} />,
      label: "Üyelik Bilgilerim",
    },
    {
      href: "/#",
      icon: <Logout stroke={colors.neutrals.grey04} />,
      label: "Çıkış Yap",
    },
  ];
  return (
    <div ref={dropdownRef} style={{ width: "100%" }}>
      <NSDropdownButtonMobileStyle.Button
        isDark={isDark}
        onClick={handleButtonClick}
      >
        {children} <ChevronDown stroke={colors.light.light} />
      </NSDropdownButtonMobileStyle.Button>

      {isOpen && (
        <NSDropdownButtonMobileStyle.DropdownContent>
          <NSDropdownButtonMobileStyle.DropdownContentContainer>
            {menuItems.map((menuItem, index) => {
              return (
                <NSDropdownButtonMobileStyle.Menu
                  key={index}
                  href={menuItem.href}
                >
                  <NSDropdownButtonMobileStyle.DropdownContentItemContainer
                    isActive={menuItem.href === location.pathname}
                  >
                    {menuItem.label}
                  </NSDropdownButtonMobileStyle.DropdownContentItemContainer>
                </NSDropdownButtonMobileStyle.Menu>
              );
            })}
          </NSDropdownButtonMobileStyle.DropdownContentContainer>
        </NSDropdownButtonMobileStyle.DropdownContent>
      )}
    </div>
  );
};
