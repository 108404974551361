import { DrawerCard } from "../../components/DrawerCard/DrawerCard";
import colors from "../../style/colors";
import { footerData, headerAnchorList } from "../../util/headerFooterData";
import useDeviceType from "../../util/isMobile";
import SustainWithUsDesktop from "./DesktopView/SustainWithUsDesktop";

import SustainWithUsMobile from "./MobileView/SustainWithUsMobile";

import { NSSustainWithUsType } from "./SustainWithUs.types";

const sustainWithUsData = (isMobile: boolean) => {
  return {
    description1:
      "Doğuş Otomotiv din, dil, ırk, renk, cinsiyet, yaş, etnik köken, engellilik ya da yasal düzenlemelerle korunan diğer sosyal statülerden bağımsız bir çalışma ortamı sunar.",
    title2: "İnsan Hakları",
    description2:
      "Çalışma arkadaşlarımızın insan hakları konusunda farkındalıklarını artırmak için farklı departmanlardan temsilcilerinin yer aldığı İnsan Hakları çalışma grubu oluşturulmuş olup bu grup düzenli aralıklarla eğitim ve çalıştaylarla bir araya gelerek gelişim planlamaları oluşturmaktadırlar.",
    title3: "Aile İçi Şiddete Karşı İşyeri Politikası",
    description3:
      "2021 yılında bu kapsamda her türlü şiddet ve tehditten uzak, sağlıklı ve güvenli bir çalışma ortamını sağlamak amacıyla “Aile İçi Şiddete Karşı İşyeri Politikası” yayınlanmıştır. Doğuş Otomotiv, Sabancı Üniversitesi’nin desteklediği “İş Dünyası Aile İçi Şiddete Karşı” (BADV) projesine dahil olarak politikaları hayata geçiren ilk otomotiv şirketi olmuştur.",
    title4: "Kadınlara Koçluk ve Mentorluk Programı",
    description4:
      "Kadın çalışma arkadaşlarının koçluk ve mentorluk yeteneklerini geliştirmelerini sağlayarak, var olan liderlik özelliklerini ortaya çıkarıp iş ortamında kullanmalarına olanak yaratmak üzerine tasarlanmıştır.",
    title5: "TEV Bursiyeri Kız Öğrencilere Mentorluk ve Gelişim Programı",
    description5:
      "Türk Eğitim Vakfı (TEV) iş birliği ile kız üniversite öğrencilerine yönelik hazırlanmış bir gelişim programıdır. Bir üst bölümde yer alan “koçluk ve mentorluk” programına katılan kadın yöneticiler, bu programda edindikleri becerileri kullanarak TEV bursiyeri kız öğrencilere mentorluk desteği vermektedir. İş hayatını yakından tanıyarak, kariyer sahibi güçlü birer birey olma yolunda gelişim göstermelerine katkı sağlamak için hazırlanan programda mentorluğun yanı sıra eğitimler, mülakat deneyimi ve şirketteki farklı rollerin tanıtımı da yer almaktadır.",
    title6: "Topluma Katkı Sağlayan Projelerimiz ve Raporlarımız",
    description6:
      "Tristique et egestas quis ipsum suspendisse ultrices. Cras adipiscing enim eu turpis egestas pretium. ",
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    statementCard: {
      isMobile: false,
      background: colors.orange.orange03,
      title: "Eşit Haklar ve Fırsatlar",
      description:
        "Doğuş Otomotiv din, dil, ırk, renk, cinsiyet, yaş, etnik köken, engellilik, vatandaşlık hali ya da yasal düzenlemelerle korunan diğer sosyal statülerden bağımsız bir “Çalışanlar ve İyi İş Ortamı Politikası” uygulamaktadır.. Ayrıca yeni çalışma ofisimiz engelli çalışma arkadaşları için erişebilirlik standartları göz önünde bulundurularak düzenlenmiştir.",
    },
    animatedTextButton: {
      label: "İş'te",
      isSmall: true,
      isMobile: false,
      animatedLabelList: [
        { label: "eşitlik", color: colors.green.green01 },
        { label: "çeşitlilik", color: colors.green.green01 },
        { label: "kapsayıcılık", color: colors.green.green01 },
      ],
    },
    imageCarousel1: {
      isMobile: false,
      imageList: [
        {
          image: "/images/sustain-with-us/aile_ici_1.jpg",
          thumbnail: "/images/sustain-with-us/aile_ici_1_thumbnail.png",
        },
        {
          image: "/images/sustain-with-us/aile_ici_2.jpg",
          thumbnail: "/images/sustain-with-us/aile_ici_2_thumbnail.png",
        },
        {
          image: "/images/sustain-with-us/aile_ici_3.jpg",
          thumbnail: "/images/sustain-with-us/aile_ici_3_thumbnail.png",
        },
      ],
      displayImagePreview: true,
    },
    imageCarousel2: {
      isMobile: false,
      imageList: [
        {
          image: "/images/sustain-with-us/aile_ici_4.jpg",
          thumbnail: "/images/sustain-with-us/aile_ici_4_thumbnail.jpg",
        },
        { image: "images/sustain-with-us/slider-2-1.png" },
        { image: "images/sustain-with-us/slider-2-2.png" },
        { image: "images/sustain-with-us/slider-2-3.png" },
      ],
      displayImagePreview: true,
    },
    imageCarousel3: {
      isMobile: false,
      imageList: [
        { image: "images/sustain-with-us/slider-3-1.png" },
        { image: "images/sustain-with-us/slider-3-2.png" },
        { image: "images/sustain-with-us/slider-3-3.png" },
        { image: "images/sustain-with-us/slider-3-4.png" },
        { image: "images/sustain-with-us/slider-3-1.png" },
        { image: "images/sustain-with-us/slider-3-2.png" },
        { image: "images/sustain-with-us/slider-3-3.png" },
        { image: "images/sustain-with-us/slider-3-4.png" },
      ],
      displayImagePreview: true,
    },
    imageCarousel4: {
      isMobile: false,
      imageList: [
        {
          image: "images/sustain-with-us/sustain_1.jpg",
          thumbnail: "images/sustain-with-us/sustain_1_thumbnail.jpg",
        },
        {
          image: "images/sustain-with-us/sustain_2.jpg",
          thumbnail: "images/sustain-with-us/sustain_2_thumbnail.jpg",
        },
        {
          image: "images/sustain-with-us/sustain_3.jpg",
          thumbnail: "images/sustain-with-us/sustain_3_thumbnail.jpg",
        },
        {
          image: "images/sustain-with-us/sustain_4.jpg",
          thumbnail: "images/sustain-with-us/sustain_4.jpg",
        },
      ],
      displayImagePreview: true,
    },

    statementCard2: {
      isMobile: false,
      background: colors.blue.blue01,
      title: "İş’te Eşitlik",
      description:
        "Doğuş Otomotiv, kadınların işgücüne katılımını artırmayı temel hedeflerinden biri olarak görmekte ve “İş’te Eşitlik” programı ile hem kadın çalışan sayısını artırmayı hem de onların karar mekanizmalarında daha etkin hale gelmesini amaçlamaktadır.  2022 yılında BM Toplumsal Cinsiyet Eşitliği ve Kadının Güçlenmesi Biriminin ortaya koyduğu, işyerinde cinsiyet eşitliğine vurgu yapan Women Empowerment Principles (WEP) (Kadının Güçlenmesi Prensipleri) imzacısı olduk.",
    },
    textList1: {
      isMobile: false,
      title: "Sürdürebilirlik",
      list: [
        "Çalışma arkadaşlarımız her yıl İstanbul Maratonu’nda iyilik için koşmaktadır.",
        "8 Mart Dünya Kadınlar Günü için, her yıl kadın emeğini güçlendirme platformları ve kadın girişimcileri destekliyoruz.",
        "23 Nisan Ulusal Egemenlik ve Çocuk Bayramı hem çalışma arkadaşlarımızın çocukları ile hem de köy okulu öğrencileri ile farklı etkinliklerle kutlanmaktadır.",
        "Doğuş Otomotiv çalışma arkadaşlarının yakınlarına yönelik CV danışmanlığı ve mülakat deneyimi etkinliği yapılmaktadır.",
        "MT Programı dahilinde tüm MT arkadaşlarımızla sürdürülebilirlik projelerini hayata geçiriyoruz.",
        "2 Aralık Engelliler Günü’nde her yıl belirli aralıklarla engelli çalışma arkadaşlarımızla bir araya gelerek, ihtiyaçlarını ve beklentileri doğrultusunda aksiyon planları oluşturuyoruz.",
      ],
      description:
        "Uzun yıllardır Doğuş Otomotiv sürdürülebilirliğini odak alanı haline getirmiş olup tüm bakış açısı ve iş süreçlerini bu doğrultuda şekillendirmektedir. 2009 yılından bu yana Kurumsal Sürdürülebilirlik Raporları yayınlamakta olup 2010 yılında imzalanan  Birleşmiş Milletler Küresel İlkeler Sözleşmesi’nin (UN Global Compact) 10 ilkesine uygun olarak ve yine Birleşmiş Milletler Sürdürülebilir Kalkınma Amaçlarına katkı sağlayan sürdürülebilirlik performansları ortaya koyarak her geçen yıl hedeflerine daha fazla yaklaşmaktadır.",
    },

    roundedCardCarousel: {
      isMobile: false,
      cardList: [
        {
          isMobile: false,
          title: "Doğuş’tan Meslek Sahibi Projesi",
          children: (
            <DrawerCard
              title="Doğuş’tan Meslek Sahibi Projesi"
              description={
                <div style={{ fontSize: 15 }}>
                  Doğuş’tan Meslek Sahibi; Doğuş Otomotiv’in Meslek Liseleri ile
                  olan iş birlikleri kapsamında hayata geçirilen projedir.
                  Türkiye'de motorlu araçlar teknolojisi alanında 6 ilde 8 okula
                  Doğuş Otomotiv tarafından VW Laboratuvar sınıfları açılmıştır.
                  Bu sınıflarda okuyan çocuklarımıza malzeme, kıyafet, eğitim,
                  teknik gezi, staj ve istihdam süreçleri gibi konularda destek
                  verilmekte; veli bilgilendirme ve bilinçlendirme toplantıları
                  düzenlenmektedir.
                  <br />
                  <br />
                  <ul style={{ listStyle: "disc", marginLeft: 12 }}>
                    <li>Öğrencilerin eğitimine katkı,</li>
                    <li>
                      Şirket eğitmenlerinin desteği ile teknik bilgilendirme
                      toplantıları,
                    </li>
                    <li>
                      Staj, çıraklık programları, mesleki eğitim programı ve
                      mentorluk programları,
                    </li>
                    <li>
                      MEB İş birliği ile şirketlerin mesleki lisesine yatırımı
                      yapması,
                      <ul style={{ listStyle: "disc", marginLeft: 24 }}>
                        <li>Malzeme Tedariği </li>
                        <li>Fiziki Koşulların İyileştirilmesi</li>
                        <li>Öğrenim Materyalleri Sağlama</li>
                      </ul>
                    </li>
                    <li>
                      Motorlu araçlar teknolojisi alanında eğitim gören
                      öğrencilerin sektöre kazanımının sağlanması,
                    </li>
                    <li>
                      Otomotiv Alanı Yatkınlık Belirleme Ölçeği ile Kariyer
                      Planlaması.
                    </li>
                    <li>
                      Marka Yönetimi ve bölüm öğretmenleriyle öğrenci seçimi,
                    </li>
                    <li>
                      Üniversite sertifikalı kişisel ve mesleki gelişim
                      eğitimleri
                    </li>
                  </ul>
                </div>
              }
              list={[]}
            />
          ),
          image: "/images/sustain-with-us/dogustanMeslekSahibi.png",
        },
        {
          isMobile: false,
          title: "Trafik Hayattır",
          onClick: () =>
            window.open(
              "https://www.dogusgrubu.com.tr/tr/insan-kaynaklari/dogus-ta-is-firsatlari"
            ),
          image: "/images/sustain-with-us/rounded-2.png",
        },
        {
          isMobile: false,
          title: "Kurumsal Sürdürebilirlik Projesi",
          onClick: () =>
            window.open(
              "https://www.dogusgrubu.com.tr/tr/insan-kaynaklari/dogus-ta-is-firsatlari"
            ),
          image: "/images/sustain-with-us/rounded-3.png",
        },
      ],
    },
  } as NSSustainWithUsType.ISustainWithUsData;
};

function SustainWithUs() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <SustainWithUsMobile sustainWithUsData={sustainWithUsData(isMobile)} />
    );
  } else {
    return (
      <SustainWithUsDesktop sustainWithUsData={sustainWithUsData(isMobile)} />
    );
  }
}

export default SustainWithUs;
