import useDeviceType from "../../../util/isMobile";
import ApplicationsPageDesktop from "./DesktopView/ApplicationsPageDesktop";
import ApplicationsPageMobile from "./MobileView/ApplicationsPageMobile";
import { footerData, headerAnchorList } from "../../../util/headerFooterData";

const applicationsData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    logoClicked: () => {},
  };
};

function ApplicationsPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return (
      <ApplicationsPageMobile applicationsData={applicationsData(isMobile)} />
    );
  } else {
    return (
      <ApplicationsPageDesktop applicationsData={applicationsData(isMobile)} />
    );
  }
}

export default ApplicationsPage;
