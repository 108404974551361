import { footerData, headerAnchorList } from "../../../util/headerFooterData";
import useDeviceType from "../../../util/isMobile";
import StatusPageDesktop from "./DesktopView/StatusPageDesktop";
import StatusPageMobile from "./MobileView/StatusPageMobile";

const statusData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    logoClicked: () => {},
  };
};

function StatusPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <StatusPageMobile statusData={statusData(isMobile)} />;
  } else {
    return <StatusPageDesktop statusData={statusData(isMobile)} />;
  }
}

export default StatusPage;
