import useDeviceType from "../../util/isMobile";
import JoinUsPageDesktop from "./DesktopView/JoinUsPageDesktop";
import { NsJoinUsPageType } from "./JoinUsPage.types";
import JoinUsPageMobile from "./MobileView/JoinUsPageMobile";
import { ReactComponent as CarSun } from "../../assets/icons/carSun.svg";
import { ReactComponent as WrenchHand } from "../../assets/icons/wrenchHand.svg";
import { ReactComponent as PlayAndLock } from "../../assets/icons/playAndLock.svg";
import { ReactComponent as Ship } from "../../assets/icons/ship.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as JusticeHammer } from "../../assets/icons/justiceHammer.svg";
import { ReactComponent as Document } from "../../assets/icons/document.svg";
import { ReactComponent as SmallFingerprint } from "../../assets/icons/smallFingerprint.svg";
import { ReactComponent as UserCollab } from "../../assets/icons/userCollab.svg";
import { ReactComponent as UserArrow } from "../../assets/icons/userArrow.svg";
import { ReactComponent as Charge } from "../../assets/icons/charge.svg";
import { ReactComponent as Shark } from "../../assets/icons/shark.svg";

import colors from "../../style/colors";
import { NSSingleRoundedCardStyle } from "../../components/SingleRoundedCard/SingleRoundedCard.style";
import { footerData, headerAnchorList } from "../../util/headerFooterData";

const joinUsPageData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,

    iconListCard: {
      isMobile: false,
      title: "Bizimle Çalışabileceğin Alanlar",
      buttonTitle: "Kariyer Fırsatları",
      onClick: () =>
        window.open(
          "https://www.dogusotomotiv.com.tr/tr/kariyer/basvuru/acikpozisyonlar",
          "_blank"
        ),
      badge: {
        textVariant: "JOINUS",
        iconVariant: "ADD",
        isMobile: false,
        color: "BLUE",
      },
      iconList: [
        {
          title: "Satış",
          icon: <CarSun />,
          description: (
            <div>
              Markalarımızın satış departmanlarında yetkili satış noktalarının
              kanal yönetimi gerçekleştirilmektedir.
              <ul>
                <li>Satış Bölge Yönetimi</li>
                <li>Filo ve Özel Satışlar Yönetimi</li>
                <li>Satış Koordinasyon</li>
                <li>Planlama ve Lojistik</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Satış Sonrası Hizmetler",
          icon: <WrenchHand />,
          description: (
            <div>
              Markalarımızın satış sonrası hizmetler departmanlarında yetkili
              servis noktalarının kanal yönetimi gerçekleştirilmektedir.
              <ul>
                <li> Servis Bölge Yönetimi</li>
                <li> Yedek Parça ve Koordinasyon</li>
                <li> Teknik Destek</li>
                <li> Teknik Eğitim</li>
                <li> Müşteri İlişkileri</li>
                <li> Garanti Süreçleri Yönetimi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Pazarlama",
          icon: <PlayAndLock />,
          description: (
            <div>
              Markalarımızın 360 pazarlama iletişim çalışmaları yapılmaktadır.
              <br />
              <br />
              <ul>
                <li>Dijital Pazarlama</li>
                <li>Konvansiyonel Pazarlama</li>
                <li>Ürün Yönetimi</li>
                <li>Ürün Eğitimi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Yedek Parça ve Lojistik",
          icon: <Ship />,
          description: (
            <div>
              Tüm markalarımızın araç ve yedek parça tedarik, ithalat, operasyon
              yönetimi, depolama ve sevkiyat işlemleri gerçekleşmektedir.
              <br />
              <br />
              <ul>
                <li> Yedek Parça Operasyon</li>
                <li> Yedek Parça Pazarlama</li>
                <li> İthalat</li>
                <li> Yedek Parça Depo</li>
                <li> Araç Dağıtım</li>
                <li> Homologasyon</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Yetkili Satıcı Geliştirme",
          icon: <Phone />,
          description: (
            <div>
              Yetkili Satış ve Servis noktalarımızın iş süreçlerinin ve mimari
              konseptlerinin geliştirme ve iyileştirme projeleri takip
              edilmektedir.
              <br />
              <br />
              <ul>
                <li>Mimari Süreç Yönetimi</li>
                <li> Yetkili Satıcı Geliştirme</li>
                <li> Kalite Yönetim Sistemleri ve Denetimi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Hukuk",
          icon: <JusticeHammer />,
          description: (
            <div>
              Şirketin dava ve sözleşme yönetimi, hukuki danışmanlık süreçleri
              takip edilmektedir.
            </div>
          ),
        },
        {
          title: "İç Denetim",
          icon: <Document />,
          description: (
            <div>
              Şirket stratejilerinin hayata geçirilmesindeki süreçlerin
              incelenmesi, denetlenmesi ve yapılan incelemelere ilişkin
              raporlamaların hazırlanma süreçleri takip edilmektedir.
            </div>
          ),
        },
        {
          title: "Dijital Dönüşüm ve Kurumsal İletişim",
          icon: <SmallFingerprint />,
          description: (
            <div>
              Doğuş Otomotiv markasının pazarlama süreçleri, müşteri deneyimi
              süreçleri, dijital iş geliştirme projeleri ve müşteri talep
              yönetimi gerçekleştirilmektedir.
              <br />
              <br />
              <ul>
                <li>Marka Yönetimi ve CRM</li>
                <li> Kurumsal İletişim ve Sürdürebilirlik</li>
                <li> Dijital Dönüşüm</li>
                <li> DİM (Değer ve İlgi Merkezi)</li>
                <li> Doğuş Otomotiv Plus+ Müşteri Deneyim Merkezi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "İnsan Kaynakları ve Süreç Yönetimi",
          icon: <UserCollab />,
          description: (
            <div>
              Yetenek kazanımı, organizasyonel gelişim, çalışan hakları, eğitim
              ve gelişim, ik veri analitiği, süreç yönetimi,idari işler ve
              yetkilisi satıcı insan kaynakları geliştirme süreçlerinin yönetimi
              gerçekleştirilmektedir.
              <br />
              <br />
              <ul>
                <li>İnsan Kaynakları</li>
                <li>İdari ve Teknik İşler</li>
                <li>Süreç Yönetimi</li>
                <li>Yetkili Satıcı İK Geliştirme (Drive)</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Mali İşler",
          icon: <UserArrow />,
          description: (
            <div>
              Finans yönetimi, Muhasebe, bütçe planlama, satın alma, uyum, risk
              yönetimi, yatırımcı ilişkileri, süreçlerin operasyon takip,
              yönetimi ve kontrolü yapılmaktadır.
              <br />
              <br />
              <ul>
                <li> Finans Yönetimi</li>
                <li> Bütçe Planlama ve Finansal Raporlama</li>
                <li> Finans Kontrol ve Yatırımcı İlişkileri</li>
                <li> Muhasebe</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Elektrikli Araç Şarj İstasyon Koordinasyonu",
          icon: <Charge />,
          description: (
            <div>
              D-Power markamız çatısı altındaki elektrikli şarj istasyonlarının
              kurulum ve iyileştirme süreçleri gerçekleştirilmektedir.
            </div>
          ),
        },
        {
          title: "Denizcilik",
          icon: <Shark />,
          description: (
            <div>
              Doğuş Marine Services markamız ile yat markalarının satış,
              periyodik bakım ve onarım işlemlerinin yanı sıra sigorta operasyon
              süreçleri takip edilmektedir.
            </div>
          ),
        },
      ],
      description:
        "Doğuş Otomotiv’de çalışabileceğin fonksiyonları inceleyebilir, kariyer fırsatları linkine tıklayarak sana en yakın pozisyona başvurabilirsin.",
    },
    arrowList: {
      cardList: [
        {
          title: "Başvuru",
          number: "01",
          description:
            "İşe alım platformlarında bulunan ilanlarımıza başvurunu yapabilirsin.",
        },
        {
          title: "İnceleme",
          number: "02",
          description:
            "Başvuru yaptıktan sonra özgeçmişin değerlendirmeye alınır.",
        },
        {
          title: "Görüşme",
          number: "03",
          description: "Online veya yüz yüze olarak görüşme aşamasına geçilir.",
        },
        {
          title: "Değerlendirme Süreçleri",
          number: "04",
          description: "Daha sonrasında sınav süreci gerçekleştirilir.",
        },
        {
          title: "Vaka Analizi ve Sunum",
          number: "05",
          description: "Belirli roller için gerçekleştirilir.",
        },
        {
          title: "İşe Alım",
          number: "06",
          description:
            "Aşamaların olumlu ilerlemesi halinde işe alım sürecine geçilir.",
        },
      ],
      isMobile: false,
    },
    singleRoundedCard: {
      isMobile: false,
      title: (
        <NSSingleRoundedCardStyle.TitleIcon
          src={"images/join-us/bizdeStaj.png"}
          alt="Bizde Staj"
        />
      ),
      backgroundColor: colors.blue.blue01,
      children: (
        <div>
          <b>Proje Bazlı Staj Programı:</b> 3. Sınıf,4. Sınıf, yeni mezun veya
          yüksek lisans öğrencilerinin dahil olduğu staj programıdır. Proje
          bazlı stajyerler, hafta en az 3 tam gün, 5 aylık süre boyunca
          stajlarını gerçekleştirmektedir.
          <br />
          <br />
          <b>Yaz Dönemi Staj Programı:</b> Yaz staj programında, stajyer
          adayları öğrenim gördükleri bölümün gereklilikleri nedeniyle zorunlu
          stajlarını maksimum 2 ay boyunca Mayıs- Eylül ayları arasında
          gerçekleştirmektedir.
          <br />
          <br />
          <b>Meslek Lisesi Staj Programı:</b> Meslek lisesi stajyerleri yaz
          döneminde (Haziran-Eylül) ve kış döneminde (Eylül-Haziran) stajlarını
          gerçekleştirmektedir.
          <br />
          <br />
          Staj programlarımıza dahil olan arkadaşlarımız, Doğuş Otomotiv
          çalışanlarıyla birlikte cesaretle keşfeder, tutkuyla ve yenilikçi
          bakış açısı ile farklı deneyimler kazanırlar. Çeşitli departman
          projelerinde yer alarak kişisel ve mesleki gelişimlerine katkı
          sağlarlar.
        </div>
      ),
    },
    videoPlayerImageAndVideo: {
      image: "images/video-card-2.png",
      video: "https://download.samplelib.com/mp4/sample-5s.mp4",
    },
    imageCarouselData: {
      displayImagePreview: false,
      isMobile: false,
      imageList: [
        { image: "images/university/bilgi.png" },
        { image: "images/university/bahcesehir.png" },
        { image: "images/university/koc.png" },
        { image: "images/university/sabanci.png" },
        { image: "images/university/bahcesehir.png" },
        { image: "images/university/koc.png" },
        { image: "images/university/sabanci.png" },
      ],
    },
    lowerImageList: {
      imageList: [
        "images/join-us/DOAS_Gelecek_Sensin_1.jpg",
        "images/join-us/DOAS_Gelecek_Sensin_2.jpg",
        "images/join-us/DOAS_Gelecek_Sensin_3.jpg",
      ],
      isMobile: false,
    },
  } as NsJoinUsPageType.IJoinUsPageData;
};

function JoinUsPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <JoinUsPageMobile joinUsPageData={joinUsPageData(isMobile)} />;
  } else {
    return <JoinUsPageDesktop joinUsPageData={joinUsPageData(isMobile)} />;
  }
}

export default JoinUsPage;
