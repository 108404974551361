import colors from "../../style/colors";
import useDeviceType from "../../util/isMobile";
import HomePageDesktop from "./DesktopView/HomePageDesktop";
import { ReactComponent as AcikPozisyonlar } from "../../assets/illustrations/acik_pozisyonlar.svg";
import { ReactComponent as Fonksiyonlarimiz } from "../../assets/illustrations/fonksiyonlarimiz.svg";
import { ReactComponent as GelisimOlanaklari } from "../../assets/illustrations/gelisim_olanaklari.svg";
import { ReactComponent as StajOlanaklari } from "../../assets/illustrations/staj_olanaklari.svg";
import { ReactComponent as YanFaydalar } from "../../assets/illustrations/yan_faydalar.svg";

import HomePageMobile from "./MobileView/HomePageMobile";
import { footerData, headerAnchorList } from "../../util/headerFooterData";
import { NsHomePageType } from "./Homepage.types";

const homePageData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    logoClicked: () => {},
    textButtonLabel: "Bizimle Birlikte",
    textButtonChangingSpeed: 3000,
    animatedLabelList: [
      {
        label: "Yenilikçi Ol",
        color: colors.green.green01,
      },
      {
        label: "Deneyimle",
        color: colors.blue.blue01,
      },
      {
        label: "Tutkulu Ol ",
        color: colors.yellow.yellow01,
      },
      {
        label: "Bu kültüre yön ver",
        color: colors.orange.orange01,
      },
    ],
    animatedArrowButtonLabel: "Kariyer Fırsatları",
    animatedArrowButtonClick: () => {
      window.open(
        "https://www.dogusotomotiv.com.tr/tr/kariyer/basvuru/acik-pozisyonlar"
      );
    },
    quoteLargeText:
      "“Mantık ve düşünce sınavını kazanan her tasarımınız zaman ölçeğinde bir gün mutlaka gerçekleşecektir.”",
    quoteSubTitle: "Ayhan Şahenk",
    quoteSecondSubTitle: "Doğuş Grubu'nun Kurucusu ve Ebedi Başkanı",
    slidingBannerData: [
      "images/image-slider-1.png",
      "images/image-slider-2.png",
      "images/image-slider-3.png",
      "images/image-slider-4.png",
      "images/image-slider-5.png",
      "images/image-slider-1.png",
      "images/image-slider-2.png",
      "images/image-slider-3.png",
      "images/image-slider-4.png",
      "images/image-slider-5.png",
    ],
    slidingBannerReverseData: [
      "images/image-slider-5.png",
      "images/image-slider-6.png",
      "images/image-slider-7.png",
      "images/image-slider-8.png",
      "images/image-slider-9.png",
      "images/image-slider-10.png",
      "images/image-slider-5.png",
      "images/image-slider-6.png",
      "images/image-slider-7.png",
      "images/image-slider-8.png",
      "images/image-slider-9.png",
      "images/image-slider-10.png",
    ],
    rolloverImageData: [
      {
        front: "images/cars/car-1.png",
        back: "images/cars/car-1-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-2.png",
        back: "images/cars/car-2-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-3.png",
        back: "images/cars/car-3-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-4.png",
        back: "images/cars/car-4-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-7.png",
        back: "images/cars/car-7-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-5.png",
        back: "images/cars/car-5-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-6.png",
        back: "images/cars/car-6-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },

      {
        front: "images/cars/car-8.png",
        back: "images/cars/car-8-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-9.png",
        back: "images/cars/car-9-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },

      {
        front: "images/cars/car-12.png",
        back: "images/cars/car-12-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/wielton.jpg",
        back: "images/cars/wielton_hover.jpg",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-11.png",
        back: "images/cars/car-11-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-14-hover.png",
        back: "images/cars/car-14.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/sensat_hover.jpg",
        back: "images/cars/sensat.jpg",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-15-hover.png",
        back: "images/cars/car-15.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-10.png",
        back: "images/cars/car-10-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-13.png",
        back: "images/cars/dcharge_hover.jpg",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
    ],
    imageCarouselData: [
      { image: "images/office/office-1.png" },
      { image: "images/office/office-2.png" },
      { image: "images/office/office-3.png" },
      { image: "images/office/office-4.png" },
      { image: "images/office/office-5.png" },
      { image: "images/office/office-6.png" },
      { image: "images/office/office-7.png" },
    ],
    opportunityList: [
      {
        title: "Kariyer Fırsatları",
        description:
          "Farklı fonksiyonlarımızdaki kariyer olanaklarını incelemek ve başvurmak için tıklayabilirsin.",
        image: <AcikPozisyonlar />,
        button: {
          onClick: () => alert("clicked"),
          label: "Hemen Başvur",
        },
        badge: {
          speed: 12,
          textVariant: "JOINUS",
          iconVariant: "ADD",
          color: "ORANGE",
          isMobile: false,
        },
      },
      {
        title: "Fonksiyonlarımız",
        description:
          "Doğuş Otomativ'de çalışmak isteyebilceğin tüm fonksiyonların detaylarını inceleyebilirsin.",
        image: <Fonksiyonlarimiz />,
        button: {
          onClick: () => alert("clicked"),
          label: "Detaylı Bilgi",
        },
        badge: {
          speed: 12,
          textVariant: "JOINUS",
          iconVariant: "ADD",
          color: "ORANGE",
          isMobile: false,
        },
      },
      {
        title: "Gelişim Olanakları",
        description:
          "Eğitim ve gelişim programlarımızı inceleyebilir, oryantasyon sürecimiz hakkında detaylı bilgileri bizden dinleyebilirsin.",
        image: <GelisimOlanaklari />,
        button: {
          onClick: () => alert("clicked"),
          label: "Detaylı Bilgi",
        },
        badge: {
          speed: 12,
          textVariant: "LEARNWITHUS",
          iconVariant: "ADD",
          color: "ORANGE",
          isMobile: false,
        },
      },
      {
        title: "Staj Olanakları",
        description:
          "Kariyerine nasıl devam edeceğine karar vermek ve güzel bir deneyim elde etmek için staj olanaklarımızdan faydalanabilirsin.",
        image: <StajOlanaklari />,
        button: {
          onClick: () => alert("clicked"),
          label: "Detaylı Bilgi",
        },
        badge: {
          speed: 12,
          textVariant: "JOINUS",
          iconVariant: "ADD",
          color: "ORANGE",
          isMobile: false,
        },
      },
      {
        title: "Yan Faydalar",
        description:
          "Yan faydalarımız detaylarını videomuzu izleyerek öğrenebilirsin.",
        image: <YanFaydalar />,
        button: {
          onClick: () => alert("clicked"),
          label: "Videoyu İzle",
        },
        badge: {
          speed: 12,
          textVariant: "LISTENUS",
          iconVariant: "ADD",
          color: "ORANGE",
          isMobile: false,
        },
      },
    ],

    videoPlayerCarousel: {
      isMobile: false,
      imageList: [
        {
          imageUrl: "/images/video-slider-1.png",
          isMobile: false,
          title: "Buket Büyükkıdık",
          description: "Porsche Pazarlama Yetkilisi",
          videoUrl: "https://download.samplelib.com/mp4/sample-5s.mp4",
        },
        {
          imageUrl: "/images/video-slider-3.png",
          isMobile: false,
          title: "Burak Sencil",
          description: "Volkswagen Ticari Araç Bölge Satış Yöneticisi",
          videoUrl: "https://download.samplelib.com/mp4/sample-5s.mp4",
        },
        {
          imageUrl: "/images/video-slider-2.png",
          isMobile: false,
          title: "İlayda Sayın",
          description: "DOD İş Geliştirme Uzmanı",
          videoUrl: "https://download.samplelib.com/mp4/sample-5s.mp4",
        },
      ],
    },
    brandImages: {
      isMobile: false,
      logoList: [
        {
          image: "/images/brands/dogus-oto.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/skoda.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/dogus-sigorta.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/vdf.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/tuv.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/dogus-teknoloji.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
      ],
    },
  } as NsHomePageType.IHomePageData;
};

function HomePage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <HomePageMobile homePageData={homePageData(isMobile)} />;
  } else {
    return <HomePageDesktop homePageData={homePageData(isMobile)} />;
  }
}

export default HomePage;
