import useDeviceType from "../../../util/isMobile";
import CVPageDesktop from "./DesktopView/CVPageDesktop";
import CVPageMobile from "./MobileView/CVPageMobile";
import { footerData, headerAnchorList } from "../../../util/headerFooterData";

const cvData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    logoClicked: () => {},
  };
};

function CVPage() {
  const { isMobile, isTablet } = useDeviceType();

  if (isMobile || isTablet) {
    return <CVPageMobile cvData={cvData(isMobile)} />;
  } else {
    return <CVPageDesktop cvData={cvData(isMobile)} />;
  }
}

export default CVPage;
