import useDeviceType from "../../util/isMobile";
import KnowUsPageDesktop from "./DesktopView/KnowUsPageDesktop";
import { NsKnowUsPageType } from "./KnowUsPage.types";
import KnowUsPageMobile from "./MobileView/KnowUsPageMobile";
import { ReactComponent as Earth } from "../../assets/icons/earth.svg";
import { ReactComponent as WorldMap } from "../../assets/icons/worldMap.svg";
import { ReactComponent as MachineLearning } from "../../assets/icons/machineLearning.svg";
import { ReactComponent as Building } from "../../assets/icons/building.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as TimeStopWatch } from "../../assets/icons/timeStopWatch.svg";
import { ReactComponent as TimeCoffee } from "../../assets/icons/timeCoffee.svg";
import { ReactComponent as UserLaptop } from "../../assets/icons/userLaptop.svg";
import { ReactComponent as FoodCake } from "../../assets/icons/foodCake.svg";
import { ReactComponent as TwoPeople } from "../../assets/icons/twoPeople.svg";
import { ReactComponent as Car } from "../../assets/icons/car.svg";
import { ReactComponent as People } from "../../assets/icons/people.svg";
import { ReactComponent as Crypto } from "../../assets/icons/cryto.svg";
import { ReactComponent as TouchIdTarget } from "../../assets/icons/touchIdTarget.svg";
import { ReactComponent as TouchIdUser } from "../../assets/icons/touchIdUser.svg";
import { ReactComponent as TouchIdApproved } from "../../assets/icons/touchIdApproved.svg";
import { ReactComponent as Toggle } from "../../assets/icons/toggle.svg";
import { ReactComponent as LoveFire } from "../../assets/icons/loveFire.svg";
import { ReactComponent as Question } from "../../assets/icons/question.svg";
import { ReactComponent as Lightning } from "../../assets/icons/lightning.svg";

import colors from "../../style/colors";
import { DrawerCard } from "../../components/DrawerCard/DrawerCard";
import { footerData, headerAnchorList } from "../../util/headerFooterData";

const KnowUsPageData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    textStats: {
      isMobile: false,
      blueTitle: "Doğuş Otomotiv",
      statsList: [
        { name: "Kıta", number: "4", icon: <Earth /> },
        { name: "Ülke", number: "33", icon: <WorldMap fill="red" /> },
        {
          name: "Sektör",
          number: "7",
          icon: <MachineLearning />,
          largeImage: "/images/know-us/sectors.png",
        },
        { name: "Şirket", number: "300 +", icon: <Building /> },
      ],
      mainTitle:
        "; otomotiv, inşaat, medya, yeme-içme, turizm & perakende; gayrimenkul ve enerji sektörlerinde faaliyet gösteren Doğuş Grubu’nun bir üyesidir.",
    },
    statsCard: {
      isMobile: false,
      statsList: [
        { icon: <Calendar />, title: "Pazartesi - Cuma", subTitle: "" },
        { icon: <TimeStopWatch />, title: "07:45 - 16:45", subTitle: "" },
        { icon: <TimeCoffee />, title: "Hibrit Çalışma*", subTitle: "" },
        { icon: <UserLaptop />, title: "700", subTitle: "Çalışma Arkadaşımız" },
        { icon: <FoodCake />, title: "37", subTitle: "Yaş Ortalaması" },
        {
          icon: <TwoPeople />,
          title: "%34 - %66",
          subTitle: "Kadın - Erkek Oranı",
        },
      ],
    },
    headingText1: {
      isMobile: false,
      isVertical: false,
      title: "Neler Yaptığımızı Bizden Dinlemek İster Misin?",
      description: "",
      children: "",
    },
    videoPlayerCarousel: {
      isMobile: false,
      imageList: [
        {
          imageUrl: "/images/video-slider-4.png",
          isMobile: false,
          title: "Burak Yılmazçelik",
          description: "SEAT & CUPRA Eğitim Uzmanı",
          videoUrl: "https://download.samplelib.com/mp4/sample-5s.mp4",
        },
        {
          imageUrl: "/images/video-slider-5.png",
          isMobile: false,
          title: "Ahber Buçukoğlu",
          description: "Yedek Parça Depo Planlama Birim Yöneticisi",
          videoUrl: "https://download.samplelib.com/mp4/sample-5s.mp4",
        },
        {
          imageUrl: "/images/video-slider-6.png",
          isMobile: false,
          title: "Batuhan Sincan",
          description: "SCANIA Satış Uzmanı",
          videoUrl: "https://download.samplelib.com/mp4/sample-5s.mp4",
        },
      ],
    },
    roundedCardCarousel: {
      isMobile: false,
      cardList: [
        {
          isMobile: false,
          title: "Sadece Merak Ettiklerimizi Soruyoruz",

          image: "/images/curved-card-1.png",
          children: (
            <DrawerCard
              title="Sadece Merak
            Ettiklerimizi Soruyoruz"
              description="Doğuş Otomotiv ve Doğuş Oto üst yönetim ekiplerinden farklı konukların yer aldığı programlarda, çalışanlardan daha önce alınan sorular konuklara yöneltilmektedir. Bu sayede konuklarımızın farklı yönleri çalışma arkadaşlarımızca öğrenilmiş olur. Aşağıda geçmiş etkinlikleri görebilirsiniz."
              list={[
                {
                  innerList: [
                    "Şeref Sezer / Doğuş Oto Ankara Bölge Direktörü",
                    "Hakan Sivri / Doğuş Oto Kartal Bölge Direktörü",
                  ],
                },
                {
                  innerList: [
                    "Osman Yelkenci / Doğuş Oto Genel Müdürü",
                    "Kerem Talih / Doğuş Otomotiv / CFO",
                  ],
                },
                {
                  innerList: [
                    "Ela Kulunyar/Doğuş Otomotiv İnsan Kaynakları ve Süreç Yönetimi Genel Müdürü",
                    "Koray Bebekoğlu/Doğuş Otomotiv Dijital Dönüşüm ve Kurumsal İletişim Genel Müdürü",
                  ],
                },
              ]}
            />
          ),
        },
        {
          isMobile: false,
          title: "Bir Bilene Soralım",
          image: "/images/curved-card-2.png",
          children: (
            <DrawerCard
              title="Bir Bilene Soralım"
              description="30 Yaş altı çalışma arkadaşlarımız ile farklı rollerde görev alan müdürlerimizin bir araya geldiği sohbet toplantılarında, konuklarımızın uzmanlık alanlarına yönelik bilgi ve deneyim paylaşımı yapılmaktadır. Aşağıda geçmiş etkinlikleri görebilirsiniz."
              list={[
                {
                  title: "Porsche SSH Süreçleri ve Müşteri Deneyimi",
                  innerList: ["Mert Kızılyallı / Porsche SSH Müdürü"],
                },
                {
                  title: "Cupra Pazarlama İletişim Stratejisi",
                  innerList: ["Gökçe Bicioğlu / SEAT&Cupra Pazarlama Müdürü"],
                },
                {
                  title: "Drive’ı Yakından Tanıyalım",
                  innerList: [
                    "Emre Atasayar / Yetkili Satıcı İK Geliştirme Müdürü",
                  ],
                },
                {
                  title: "Doğuş Otomotiv’de İletişim ve Sürdürebilirlik",
                  innerList: [
                    "Ebru Kantoğlu / Kurumsal İletişim ve Sürdürülebilirlik Müdürü",
                  ],
                },
                {
                  title: "Müşteri Deneyiminde Verinin Önemi",
                  innerList: ["Mert Şenkal / Marka Yönetimi ve CRM Müdürü"],
                },
                {
                  title: "Müşteri Deneyiminde Verinin Önemi",
                  innerList: ["Mert Şenkal / Marka Yönetimi ve CRM Müdürü"],
                },
                {
                  title: "Müşteri Deneyiminde Verinin Önemi",
                  innerList: ["Mert Şenkal / Marka Yönetimi ve CRM Müdürü"],
                },
                {
                  title: "Müşteri Deneyiminde Verinin Önemi",
                  innerList: ["Mert Şenkal / Marka Yönetimi ve CRM Müdürü"],
                },
              ]}
            />
          ),
        },
        {
          isMobile: false,
          title: "Kariyer Yolculuğu Sohbetleri",
          image: "/images/curved-card-3.png",
          children: (
            <DrawerCard
              title="Kariyer Yolculuğu Sohbetleri"
              description="Değer ve İlgi Merkezimizdeki(DİM) çalışma arkadaşlarımızda kariyerinde farklı rollerde görev alan tecrübeli yöneticilerin bir araya geldiği sohbet toplantılarıdır. Aşağıda geçmiş etkinlikleri görebilirsiniz."
              list={[
                {
                  innerList: ["Ümit Arısoy / Doğuş Oto İnsan Kaynaklar Müdürü"],
                },
                {
                  innerList: ["Şeref Sezer/ Doğuş Oto Ankara Bölge Direktörü"],
                },
                {
                  innerList: [
                    "Orçun Kızıltepe/Sigortaladım.com Genel Müdür Yardımcısı",
                  ],
                },
              ]}
            />
          ),
        },
      ],
    },
    rolloverImageData: [
      {
        front: "images/cars/car-1.png",
        back: "images/cars/car-1-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-2.png",
        back: "images/cars/car-2-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-3.png",
        back: "images/cars/car-3-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-4.png",
        back: "images/cars/car-4-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-7.png",
        back: "images/cars/car-7-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-5.png",
        back: "images/cars/car-5-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-6.png",
        back: "images/cars/car-6-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },

      {
        front: "images/cars/car-8.png",
        back: "images/cars/car-8-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-9.png",
        back: "images/cars/car-9-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },

      {
        front: "images/cars/car-12.png",
        back: "images/cars/car-12-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/wielton.jpg",
        back: "images/cars/wielton_hover.jpg",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-11.png",
        back: "images/cars/car-11-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-14-hover.png",
        back: "images/cars/car-14.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/sensat_hover.jpg",
        back: "images/cars/sensat.jpg",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-15-hover.png",
        back: "images/cars/car-15.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-10.png",
        back: "images/cars/car-10-hover.png",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
      {
        front: "images/cars/car-13.png",
        back: "images/cars/dcharge_hover.jpg",
        link: "https://stimg.cardekho.com/images/carexteriorimages/930x620/Audi/A4/9321/1670914111785/front-left-side-47.jpg",
      },
    ],
    brandImages: {
      isMobile: false,
      logoList: [
        {
          image: "/images/brands/dogus-oto.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/skoda.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/dogus-sigorta.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/vdf.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/tuv.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
        {
          image: "/images/brands/dogus-teknoloji.png",
          link: "https://www.dogusotomotiv.com.tr/tr/kariyer/ik-uygulamalari",
        },
      ],
    },
    singleRoundedCard: {
      isMobile: false,
      title: "Yan Faydalarımız",
      backgroundColor: colors.orange.orange03,
      children: (
        <div>
          Doğuş Otomotivli olmanın bazı güzelliklerini senin için özetledik.
          Doğuş Grubu Ailesi üyeleri grup içi ve dışı pek çok markada
          kültür-sanat, sağlık, spor, alışveriş ve yeme-içme gibi kategorilerde
          ayrılacalıklardan yararlanır. Bunlar ve daha fazlası için videoyu
          izleyebilirsin.
          <br />
          <br />
          <ul>
            <li>Uzaktan Çalışma Desteği Özel Sağlık Sigortası</li>
            <li>(0-18 yaş çocuklar dahil) Hayat Sigortası</li>
            <li>Yüksek Lisans Eğitim Desteği</li>
            <li>Yabancı Dil Desteği</li>
            <li>Eğitim ve Gelişim Programları</li>
            <li>Mentorluk & Koçluk Desteği</li>
            <li>Diyetisyen Hizmeti</li> <li>Yoga Eğitimi</li>
            <li>Kadın çalışma arkadaşlarımıza özel kreş desteği</li>
          </ul>
        </div>
      ),
    },
    videoPlayerImageAndVideo: {
      image: "images/video-card-1.png",
      video: "https://download.samplelib.com/mp4/sample-5s.mp4",
    },
    iconDescriptionListMobile: {
      isMobile: false,
      statsList: [
        { title: "12 Marka", description: "", icon: <Car /> },
        { title: "10 M+ Müşteri", description: "", icon: <People /> },
        {
          title: "630 Yetkili Satıcı ve Servis Noktası",
          description: "",
          icon: <Crypto />,
        },
        {
          title: "Strateji",
          description: `Büyük Ol, Yakın Ol, Yaratıcı Ol`,
          icon: <TouchIdApproved />,
        },
        {
          title: "Vizyon",
          description: `İş planlarımızı "beklentilerin üzerinde yaratıcı hizmet" vizyonuyla oluşturuyoruz.`,
          icon: <TouchIdTarget />,
        },
        {
          title: "Misyon",
          description: `Müşteri odaklılık prensibi üzerinde çalışan, sektörünü iyi tanıyan, paydaşlarının beklentilerini bilen ve yeni beklentiler tanımlayıp, bunların en üst düzeyde tatminini hedefleyen, yaratıcı insan gücüne sahip ve bu doğrultuda teknolojiyi iyi kullanan, güvenilir, otomotiv değer zinciri alanlarında verimli ve kârlı hizmet sunmayı hedefleyen bir otomotiv şirketi olma misyonuyla hareket ediyoruz.`,
          icon: <TouchIdUser />,
        },
      ],
      mainTitle: "Doğuş Otomotiv Vizyon, Misyon ve Stratejisi",
    },
    iconDescriptionListDesktop: {
      isMobile: false,
      statsList: [
        { title: "12 Marka", description: "", icon: <Car /> },
        { title: "10 M+ Müşteri", description: "", icon: <People /> },
        {
          title: "630 Yetkili Satıcı ve Servis Noktası",
          description: "",
          icon: <Crypto />,
        },
        {
          title: "Vizyon",
          description: `İş planlarımızı "beklentilerin üzerinde yaratıcı hizmet" vizyonuyla oluşturuyoruz.`,
          icon: <TouchIdTarget />,
        },
        {
          title: "Misyon",
          description: `Müşteri odaklılık prensibi üzerinde çalışan, sektörünü iyi tanıyan, paydaşlarının beklentilerini bilen ve yeni beklentiler tanımlayıp, bunların en üst düzeyde tatminini hedefleyen, yaratıcı insan gücüne sahip ve bu doğrultuda teknolojiyi iyi kullanan, güvenilir, otomotiv değer zinciri alanlarında verimli ve kârlı hizmet sunmayı hedefleyen bir otomotiv şirketi olma misyonuyla hareket ediyoruz.`,
          icon: <TouchIdUser />,
        },
        {
          title: "Strateji",
          description: `Büyük Ol, Yakın Ol, Yaratıcı Ol`,
          icon: <TouchIdApproved />,
        },
      ],
      mainTitle: "Doğuş Otomotiv Vizyon, Misyon ve Stratejisi",
    },
    iconCardList: [
      {
        isMobile: false,
        icon: <People />,
        title: "Biz",
        description:
          "Biz, farklılıklarımızla bir olmanın güveni ve birlikte olmanın gücü ile birbirimize fırsatlar sunar, yeni ufaklara yelken açarız. Kendi kurallarımızı kendimiz koyar, takip etmek yerine sadece kendimizle rekabet ederiz.",
      },
      {
        isMobile: false,
        icon: <Toggle />,
        title: "Yenilikçi",
        description:
          "Yeniliğin öncüsü olmak için çevik davranır, veriye ve gerçeklere dayanan kararlar alırız. Dokunduğumuz her noktada insan ve toplumsal yaşamı zenginleştirmek adına teknolojik ve vizyoner çözümler getiririz.",
      },
      {
        isMobile: false,
        icon: <Crypto />,
        title: "Deneyim Odaklı",
        description:
          "Vizyoner bir rehberlik ile yaşattığımız her andan anılar yaratırız. Tüm bunlar için gereken gücü tarihimizde, kültürümüzde ve sanatımızda bulur, özgün deneyim alanları oluştururuz.",
      },
      {
        isMobile: false,
        icon: <LoveFire />,
        title: "Tutkulu",
        description:
          "İşimiz gülümsetmek, duyuları ve duyguları harekete geçirmek, herkesi kendi gibi hissettirmektir. 7/24 yayında, hayatın içinde, tutkuyla çalışırız.",
      },
      {
        isMobile: false,
        icon: <Question />,
        title: "Merak Eden",
        description:
          "Öğrenmek merakımızı ateşler ve bu yolda birbirimizi cesaretle teşvik ederek keşiflerimize devam ederiz.",
      },
      {
        isMobile: false,
        icon: <Lightning />,
        title: "Cesur",
        description:
          "Hata yapmaktan çekinmemek, her birimizin değer katmaktaki en büyük özgürlüğünü temsil eder. Cesuruz, çünkü arkamızda tutku ve memlekete ve dünyaya hizmet etmek için “İyi Bir Gelecek” vizyonu ortaya koyan Doğuş Grubu var.",
      },
    ],
    imageCarouselData: {
      displayImagePreview: true,
      isMobile: false,
      imageList: [
        {
          image: "images/office/office-1.png",
          title: "Doğuş Otomotiv Genel Müdürlüğü",
        },
        {
          image: "images/office/office-2.png",
          title: "Doğuş Otomotiv Yedek Parça ve Depo",
        },
        { image: "images/office/office-3.png", title: "Eğitim Binası" },
        { image: "images/office/office-4.png", title: "Scania Gebze" },
        {
          image: "images/office/office-5.png",
          title: "Doğuş Otomotiv Genel Müdürlüğü",
        },
        {
          image: "images/office/office-6.png",
          title: "Doğuş Otomotiv Yedek Parça ve Depo",
        },
        { image: "images/office/office-7.png", title: "Eğitim Binası" },
      ],
    },
    iconListCard: {
      isMobile: false,
      title: "Bizimle Çalışabileceğin Alanlar",
      buttonTitle: "Kariyer Fırsatları",
      badge: {
        textVariant: "JOINUS",
        iconVariant: "ADD",
        isMobile: false,
        color: "BLUE",
      },
      iconList: [
        {
          title: "Satış",
          icon: "i",
          description: (
            <div>
              Markalarımızın satış departmanlarında yetkili satış noktalarının
              kanal yönetimi gerçekleştirilmektedir.
              <br />
              <br />
              <ul>
                <li>Satış Bölge Yönetimi</li>
                <li>Filo ve Özel Satışlar Yönetimi</li>
                <li>Satış Koordinasyon</li>
                <li>Planlama ve Lojistik</li>
                <li>Planlama ve Lojistik</li>
                <li>Planlama ve Lojistik</li>
                <li>Planlama ve Lojistik</li>
                <li>Planlama ve Lojistik</li>
              </ul>
            </div>
          ),
        },
        {
          title: "selam 2",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
        {
          title: "selam 3",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
        {
          title: "selam 4",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
        {
          title: "selam 5",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
        {
          title: "selam 6",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
        {
          title: "selam 7",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
        {
          title: "selam 8",
          icon: "i",
          description:
            "this is the description this is the description this is the descriptionthis is the description",
        },
      ],
      description:
        "Doğuş Otomotiv’de çalışabileceğin fonksiyonları inceleyebilir, kariyer fırsatları linkine tıklayarak sana en yakın pozisyona başvurabilirsin.",
    },
  } as NsKnowUsPageType.IKnowUsPageData;
};

function KnowUsPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <KnowUsPageMobile knowUsPageData={KnowUsPageData(isMobile)} />;
  } else {
    return <KnowUsPageDesktop knowUsPageData={KnowUsPageData(isMobile)} />;
  }
}

export default KnowUsPage;
