import styled from "styled-components";
import colors from "../../style/colors";
import { darken } from "polished";

export namespace NSCvFormStepStyle {
  export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "ReadexPro";
    flex-direction: column;
  `;

  export const StepWrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "ReadexPro";
    flex-direction: column;
    background-color: ${colors.neutrals.grey01};
    border-radius: 8px 8px 0 0;

    @media (max-width: 1248px) {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  `;

  export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "ReadexPro";
    background-color: ${colors.neutrals.grey01};
    border-radius: 8px 8px 0 0;
  `;

  export const Step = styled.button<{
    color: string;
    active: boolean;
    isFill?: boolean;
    isStroke?: boolean;
  }>`
    border-radius: 8px;
    width: 154px;
    height: 74px;
    background-color: ${({ active, color }) =>
      active ? color : "transparent"};
    font-size: 14px;
    line-height: 22.96px;
    font-weight: 700;
    color: ${({ active, color }) => (active ? colors.light.light : color)};
    :hover {
      background-color: ${({ active, color }) =>
        active ? darken(0.01, color) : colors.shadesOfdark.dark04};
    }
    font-family: "ReadexPro";
    border: 0;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1248px) {
      height: 42px;
      border-radius: 0px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding: 0px 8px;
      width: max-content;
      & > svg {
        display: none;
      }
    }
  `;

  export const StepStar = styled.span<{ active: boolean; color: string }>`
    margin-left: 4px;
    color: ${({ active, color }) => (active ? colors.error.error01 : color)};
  `;

  export const StepContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    cursor: pointer;
    padding-bottom: 7.33px;

    @media (max-width: 1248px) {
      padding-bottom: 0px;
    }
  `;

  export const RoadPath = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: ${colors.neutrals.grey03};
    border-radius: 0 0 16px 16px;
    height: 36.81px;
  `;

  export const IndicatorContainer = styled.div<{ id: string }>`
    height: 2px;
    position: absolute;
    width: ${({ id }) => (id === "diger-alanlar" ? "157px" : "161px")};
    transition: transform 0.8s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const ProgressBar = styled.progress`
    appearance: none;
    height: 2px;
    width: 100%;
    border-radius: 100px;

    /* Chrome and Safari */
    &::-webkit-progress-bar {
      background: transparent;
      border-radius: 100px;
    }

    &::-webkit-progress-value {
      background-color: ${colors.neutrals.grey07};
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    /* Firefox */
    &::-moz-progress-bar {
      background-color: ${colors.neutrals.grey07};
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    &::-webkit-progress-value {
      transition: width 0.8s ease;
    }

    @media (max-width: 1248px) {
      display: none !important;
    }
  `;
}
