import { ReactComponent as User } from "../assets/icons/user.svg";
import { DropdownButton } from "../components/DropdownButton/DropdownButton";
import { DropdownButtonMobile } from "../components/DropdownButtonMobile/DropdownButtonMobile";
import { NSFooterType } from "../components/Footer/Footer.types";

const isLoggedIn = true;

export const headerAnchorListMobile = () => {
  return [
    isLoggedIn
      ? {
          isDropdown: true,
          label: <DropdownButtonMobile>Burak Altun</DropdownButtonMobile>,
          href: "#.",
        }
      : {
          label: "Giriş Yap/Üye Ol",
          href: "/auth/login",
        },
    { label: "Güncel Durumum", href: "/dashboard/status" },
    { label: "Açık Pozisyonlar", href: "/position/list" },
    { label: "Özgeçmişim", href: "/dashboard/cv" },
    { label: "Başvurularım", href: "/dashboard/applications" },
    { label: "Üyelik Bilgilerim", href: "/dashboard/account" },
  ];
};

export const headerAnchorListDesktop = () => {
  return [
    { label: "Bizi Tanı", href: "/know-us" },
    { label: "Bize Katıl", href: "/join-us" },
    { label: "Bizimle Öğren", href: "/learn-with-us" },
    { label: "Bizimle Sürdür", href: "/sustain-with-us" },
    { label: "Kariyer Fırsatları", href: "/position/list" },
    isLoggedIn
      ? { label: <DropdownButton>Burak Altun</DropdownButton>, href: "#." }
      : {
          label: (
            <>
              <User stroke="#FFF" /> "Giriş Yap/Üye Ol"
            </>
          ),
          href: "/auth/login",
        },
  ];
};

export const headerAnchorList = (isMobile: boolean) => {
  return isMobile ? headerAnchorListMobile() : headerAnchorListDesktop();
};

export const footerData: NSFooterType.IFooter = {
  isMobile: false,
  address:
    "Anadolu Caddesi, Şekerpınar Mahallesi No:45/D Çayırova, Şekerpınar Kocaeli",
  footerBar: {
    isMobile: false,
    copyRightTitle: "Doğuş Kariyer. Her hakkı saklıdır. ©2023.",
    termsUrl:
      "https://www.dogusotomotiv.com.tr/tr/kullanim-sartlari-ve-gizlilik-kisisel-verilerin-korunmasi",
    cookiePolicyUrl: "https://www.dogusotomotiv.com.tr/tr/cerez-politikasi",
  },
  socialLinks: [
    { name: "facebook", url: "https://www.facebook.com/dogusotomotiv" },
    { name: "instagram", url: "https://www.instagram.com/dogusotomotiv/" },
    { name: "youtube", url: "https://www.instagram.com/dogusotomotiv/" },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/company/dogus-otomotiv",
    },
    { name: "anlatsin", url: "https://www.instagram.com/dogusotomotiv/" },
  ],
  mainNavigationLinks: [
    { label: "Bizi Tanı", href: "/about-us" },
    { label: "Bize Katıl", href: "/join-us" },
    { label: "Bizimle Öğren", href: "/learn-with-us" },
    { label: "Bizimle Sürdür", href: "/sustain-with-us" },
  ],
  careerLinks: [
    {
      label: "Linkedin",
      href: "https://www.linkedin.com/company/dogus-otomotiv",
    },
    {
      label: "Kariyer.net",
      href: "https://www.kariyer.net/firma-profil/dogus-otomotiv-5094-29977?utm_campaign=DSA_Genel_&utm_source=google&utm_medium=cpc&gad_source=1&gclid=CjwKCAjw1K-zBhBIEiwAWeCOF3p4_Srv9TRj-0wbRCxl7ynq6dIkEBgHMJcF3Xh_cabFg5B0H0MAZRoCy4QQAvD_BwE",
    },
    {
      label: "Youthall",
      href: "https://www.youthall.com/tr/dogusotomotiv",
    },
    {
      label: "100s Club",
      href: "https://www.100sclub.com/",
    },
  ],
};
