import styled, { css } from "styled-components";
import typography from "../../style/typography";
import colors from "../../style/colors";

export namespace NSDropdownButtonStyle {
  export const Button = styled.button<{ isDark: boolean }>`
    font-family: "ReadexPro";
    width: auto;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.08px;
    text-align: center;
    gap: 8px;
  `;

  export const DropdownContent = styled.div<{
    width?: number | string;
    height?: number | string;
  }>`
    position: flex;
    background-color: #fff;
    min-width: 160px;
    width: 250px;
    height: auto;
    right: 4%;
    position: fixed;
    margin-top: 28px;
    z-index: 99999999;
    background: "#00000014";
    border-radius: 12px;
    padding: 16px 8px 8px 8px;
    justify-content: center;
    font-weight: 400;
  `;

  export const DropdownContentContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    @media (max-width: 1092px) {
      gap: 4px;
    }
  `;

  export const DropdownContentItemContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    position: relative;
    @media (max-width: 1092px) {
      gap: 4px;
    }
  `;

  export const Menu = styled.a<{}>`
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: "ReadexPro";
    font-weight: ${typography.fontWeights.medium};
    font-size: ${typography.smallText1.size};
    line-height: ${typography.pharagraph1_5.lineHeight};
    color: ${colors.dark.dark};
    padding: 0px 8px 0px 8px;
    height: 40px;
    width: 235px;
    border-radius: 12px;
    transition: background-color 0.5s, color 0.2s;
    &:hover {
      background-color: ${colors.neutrals.grey01};
      color: ${colors.dark.dark};

      svg {
        stroke: ${colors.blue.blue01};
      }
      svg [fill=${colors.neutrals.grey01}] {
        fill: ${colors.blue.blue01};
      }
    }
  `;

  export const DropdownContentLine = styled.div`
    border-bottom: 1px solid ${colors.neutrals.grey03};
    width: 100%;
    margin: 10px 0px;
  `;
}
