import { NSInnerPageHeaderStyle } from "./InnerPageHeader.style";
import { NSInnerPageHeaderType } from "./InnerPageHeader.types";
import { ReactComponent as Add } from "../../assets/icons/addLarge.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrowLarge.svg";
import { ReactComponent as Astrix } from "../../assets/icons/astrxLarge.svg";
import { ReactComponent as Rectangle } from "../../assets/icons/rectangle.svg";
import { useEffect, useState } from "react";

export const InnerPageHeader = ({
  isMobile,
  title,
  iconChangingSpeed = 2000,
  backgroundColor,
  iconColor,
  isIcon = true,
  children,
  isInJobPage,
}: NSInnerPageHeaderType.IInnerPageHeader) => {
  const iconList = [
    <Add key="add" />,
    <Astrix key="astrix" />,
    <Arrow key="arrow" />,
    <Rectangle key="rectangle" />,
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = (currentIndex + 1) % iconList.length;
      setCurrentIndex(nextIndex);
    }, iconChangingSpeed);

    return () => clearTimeout(timer);
  }, [currentIndex, iconChangingSpeed, iconList.length]);

  return (
    <NSInnerPageHeaderStyle.Container
      isMobile={isMobile}
      backgroundColor={backgroundColor}
      iconColor={iconColor}
    >
      {children}
      <NSInnerPageHeaderStyle.InnerContainer isMobile={isMobile}>
        {iconColor
          ? iconList.map((icon, index) => (
              <NSInnerPageHeaderStyle.IconContainer
                key={icon.key}
                isMobile={isMobile}
                isVisible={currentIndex === index}
              >
                {icon}
              </NSInnerPageHeaderStyle.IconContainer>
            ))
          : null}
        {title ? (
          <NSInnerPageHeaderStyle.Title isMobile={isMobile} isIcon={isIcon}>
            {title}
          </NSInnerPageHeaderStyle.Title>
        ) : null}
      </NSInnerPageHeaderStyle.InnerContainer>
      {iconColor ? (
        <NSInnerPageHeaderStyle.StyledFingerprint
          issmall={isMobile.toString()}
          fill={iconColor}
          isInJobPage={isInJobPage}
        />
      ) : null}
    </NSInnerPageHeaderStyle.Container>
  );
};
