import useDeviceType from "../../util/isMobile";
import LearnWithUsDesktop from "./DesktopView/LearnWithUsDesktop";
import { NSLearnWithUsType } from "./LearnWithUs.types";
import LearnWithUsMobile from "./MobileView/LearnWithUsMobile";
import { ReactComponent as CarSun } from "../../assets/icons/carSun.svg";
import { ReactComponent as WrenchHand } from "../../assets/icons/wrenchHand.svg";
import { ReactComponent as PlayAndLock } from "../../assets/icons/playAndLock.svg";
import { ReactComponent as Ship } from "../../assets/icons/ship.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as JusticeHammer } from "../../assets/icons/justiceHammer.svg";
import { ReactComponent as Document } from "../../assets/icons/document.svg";
import { ReactComponent as SmallFingerprint } from "../../assets/icons/smallFingerprint.svg";
import { ReactComponent as UserCollab } from "../../assets/icons/userCollab.svg";
import { ReactComponent as UserArrow } from "../../assets/icons/userArrow.svg";
import { ReactComponent as Charge } from "../../assets/icons/charge.svg";
import { ReactComponent as Shark } from "../../assets/icons/shark.svg";

import colors from "../../style/colors";
import { NSLearnWithUsDesktopStyle } from "./DesktopView/LearnWithUsDesktop.style";
import { footerData, headerAnchorList } from "../../util/headerFooterData";

const learnWithUsData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,

    iconListCard: {
      isMobile: false,
      title: "Bizimle Çalışabileceğin Alanlar",
      buttonTitle: "Kariyer Fırsatları",
      badge: {
        textVariant: "JOINUS",
        iconVariant: "ADD",
        isMobile: false,
        color: "BLUE",
      },
      iconList: [
        {
          title: "Satış",
          icon: <CarSun />,
          description: (
            <div>
              Markalarımızın satış departmanlarında yetkili satış noktalarının
              kanal yönetimi gerçekleştirilmektedir.
              <ul>
                <li>Satış Bölge Yönetimi</li>
                <li>Filo ve Özel Satışlar Yönetimi</li>
                <li>Satış Koordinasyon</li>
                <li>Planlama ve Lojistik</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Satış Sonrası Hizmetler",
          icon: <WrenchHand />,
          description: (
            <div>
              Markalarımızın satış sonrası hizmetler departmanlarında yetkili
              servis noktalarının kanal yönetimi gerçekleştirilmektedir.
              <ul>
                <li> Servis Bölge Yönetimi</li>
                <li> Yedek Parça ve Koordinasyon</li>
                <li> Teknik Destek</li>
                <li> Teknik Eğitim</li>
                <li> Müşteri İlişkileri</li>
                <li> Garanti Süreçleri Yönetimi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Pazarlama",
          icon: <PlayAndLock />,
          description: (
            <div>
              Markalarımızın 360 pazarlama iletişim çalışmaları yapılmaktadır.
              <br />
              <br />
              <ul>
                <li>Dijital Pazarlama</li>
                <li>Konvansiyonel Pazarlama</li>
                <li>Ürün Yönetimi</li>
                <li>Ürün Eğitimi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Yedek Parça ve Lojistik",
          icon: <Ship />,
          description: (
            <div>
              Tüm markalarımızın araç ve yedek parça tedarik, ithalat, operasyon
              yönetimi, depolama ve sevkiyat işlemleri gerçekleşmektedir.
              <br />
              <br />
              <ul>
                <li> Yedek Parça Operasyon</li>
                <li> Yedek Parça Pazarlama</li>
                <li> İthalat</li>
                <li> Yedek Parça Depo</li>
                <li> Araç Dağıtım</li>
                <li> Homologasyon</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Yetkili Satıcı Geliştirme",
          icon: <Phone />,
          description: (
            <div>
              Yetkili Satış ve Servis noktalarımızın iş süreçlerinin ve mimari
              konseptlerinin geliştirme ve iyileştirme projeleri takip
              edilmektedir.
              <br />
              <br />
              <ul>
                <li>Mimari Süreç Yönetimi</li>
                <li> Yetkili Satıcı Geliştirme</li>
                <li> Kalite Yönetim Sistemleri ve Denetimi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Hukuk",
          icon: <JusticeHammer />,
          description: (
            <div>
              Şirketin dava ve sözleşme yönetimi, hukuki danışmanlık süreçleri
              takip edilmektedir.
            </div>
          ),
        },
        {
          title: "İç Denetim",
          icon: <Document />,
          description: (
            <div>
              Şirket stratejilerinin hayata geçirilmesindeki süreçlerin
              incelenmesi, denetlenmesi ve yapılan incelemelere ilişkin
              raporlamaların hazırlanma süreçleri takip edilmektedir.
            </div>
          ),
        },
        {
          title: "Dijital Dönüşüm ve Kurumsal İletişim",
          icon: <SmallFingerprint />,
          description: (
            <div>
              Doğuş Otomotiv markasının pazarlama süreçleri, müşteri deneyimi
              süreçleri, dijital iş geliştirme projeleri ve müşteri talep
              yönetimi gerçekleştirilmektedir.
              <br />
              <br />
              <ul>
                <li>Marka Yönetimi ve CRM</li>
                <li> Kurumsal İletişim ve Sürdürebilirlik</li>
                <li> Dijital Dönüşüm</li>
                <li> DİM (Değer ve İlgi Merkezi)</li>
                <li> Doğuş Otomotiv Plus+ Müşteri Deneyim Merkezi</li>
              </ul>
            </div>
          ),
        },
        {
          title: "İnsan Kaynakları ve Süreç Yönetimi",
          icon: <UserCollab />,
          description: (
            <div>
              Yetenek kazanımı, organizasyonel gelişim, çalışan hakları, eğitim
              ve gelişim, ik veri analitiği, süreç yönetimi,idari işler ve
              yetkilisi satıcı insan kaynakları geliştirme süreçlerinin yönetimi
              gerçekleştirilmektedir.
              <br />
              <br />
              <ul>
                <li>İnsan Kaynakları</li>
                <li>İdari ve Teknik İşler</li>
                <li>Süreç Yönetimi</li>
                <li>Yetkili Satıcı İK Geliştirme (Drive)</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Mali İşler",
          icon: <UserArrow />,
          description: (
            <div>
              Finans yönetimi, Muhasebe, bütçe planlama, satın alma, uyum, risk
              yönetimi, yatırımcı ilişkileri, süreçlerin operasyon takip,
              yönetimi ve kontrolü yapılmaktadır.
              <br />
              <br />
              <ul>
                <li> Finans Yönetimi</li>
                <li> Bütçe Planlama ve Finansal Raporlama</li>
                <li> Finans Kontrol ve Yatırımcı İlişkileri</li>
                <li> Muhasebe</li>
              </ul>
            </div>
          ),
        },
        {
          title: "Elektrikli Araç Şarj İstasyon Koordinasyonu",
          icon: <Charge />,
          description: (
            <div>
              D-Power markamız çatısı altındaki elektrikli şarj istasyonlarının
              kurulum ve iyileştirme süreçleri gerçekleştirilmektedir.
            </div>
          ),
        },
        {
          title: "Denizcilik",
          icon: <Shark />,
          description: (
            <div>
              Doğuş Marine Services markamız ile yat markalarının satış,
              periyodik bakım ve onarım işlemlerinin yanı sıra sigorta operasyon
              süreçleri takip edilmektedir.
            </div>
          ),
        },
      ],
      description:
        "Doğuş Otomotiv’de çalışabileceğin fonksiyonları inceleyebilir, kariyer fırsatları linkine tıklayarak sana en yakın pozisyona başvurabilirsin.",
    },
    arrowList: {
      cardList: [
        {
          title: "Başvuru",
          number: "01",
          description:
            "İşe alım platformlarında bulunan ilanlarımıza başvurunu yapabilirsin.",
        },
        {
          title: "İnceleme",
          number: "02",
          description:
            "Başvuru yaptıktan sonra özgeçmişin değerlendirmeye alınır.",
        },
        {
          title: "Görüşme",
          number: "03",
          description: "Online veya yüz yüze olarak görüşme aşamasına geçilir.",
        },
        {
          title: "Değerlendirme Süreçleri",
          number: "04",
          description: "Daha sonrasında sınav süreci gerçekleştirilir.",
        },
        {
          title: "Vaka Analizi ve Sunum",
          number: "05",
          description: "Belirli roller için gerçekleştirilir.",
        },
        {
          title: "İşe Alım",
          number: "06",
          description:
            "Aşamaların olumlu ilerlemesi halinde işe alım sürecine geçilir.",
        },
      ],
      isMobile: false,
    },
    singleRoundedCard: {
      isMobile: false,
      textColor: colors.dark.dark,
      title:
        "İşe Yeni Başlayan Çalışma Arkadaşlarımızın Adaptasyon Süreci Nasıl Geçer?",
      backgroundColor: colors.blue.blue03,
      children: (
        <div>
          Aramıza katılan tüm çalışma arkadaşlarımız için yeni nesil oryantasyon
          programı düzenlenmektedir.
          <br />
          <br />
          İnsan Kaynaklarının koordine ettiği oryantasyon programı işe yeni
          başlayan çalışma arkadaşımızın şirkete ve işlerine hızlı uyum
          sağlamaları için düzenlenmektedir.
          <br />
          <br />
          Farklı departman yöneticilerinin iş alanlarını ve şirket dinamiklerini
          aktardığı program ile yeni başlayan çalışma arkadaşımız şirketi geniş
          kapsamda tanıma fırsatı bulmaktadır.
        </div>
      ),
    },
    videoPlayerImageAndVideo: {
      image: "images/video-card-2.png",
      video: "https://download.samplelib.com/mp4/sample-5s.mp4",
    },
    imageCarouselData: {
      isMobile: false,
      displayImagePreview: true,
      imageList: [
        { image: "images/learn-with-us/learn-with-us-1.png" },
        { image: "images/learn-with-us/learn-with-us-2.png" },
        { image: "images/learn-with-us/learn-with-us-3.png" },
        { image: "images/learn-with-us/learn-with-us-4.png" },
      ],
    },
    lowerImageList: [
      "images/join-us/join-us-1.png",
      "images/join-us/join-us-2.png",
      "images/join-us/join-us-3.png",
    ],
    opportunityList: [
      {
        title: "Fikir Platformu",
        description:
          "Çalışanlarımız, mevcut uygulama ya da süreçlerle ilgili fikirlerini ve departman süreç iyileştirme projelerini paylaşabildiği gibi aynı zamanda kurum içi girişimcilik ve yeni iş fikirlerini de Fikir Platformu üzerinden paylaşıp hayata geçişini takip edebilmektedir. Çalışanlar paylaştıkları fikirlerden Fikir Coin kazanmakta ve topladıkları bu Fikir Coin’leri çeke çevirip harcayabildiği gibi Fikir Komitesi tarafından seçilen en iyi fikirler de ayrıca ödüllendirilmektedir.",
        image: (
          <NSLearnWithUsDesktopStyle.CurvedCardImage
            src="images/learn-with-us/fikir-platformu.png"
            alt=""
          />
        ),
      },
      {
        title: "Yalın Liderlik Eğitim Programı",
        description:
          "Sürekli iyileştirme bakış açısının ve yalın felsefesinin çalışan kültürü haline gelmesi amacıyla “Yalın Liderlik Eğitim Programı” yürütülmektedir. 4 günlük teorik eğitimi ardından Yalın Liderlik Eğitim Projesini tamamlayan çalışma arkadaşlarımız Yalın Lideri sertifikası almaya hak kazanmaktadır. Yalın liderlerimiz departmanlarındaki fikir ve projelerin hayata geçirilmesini desteklemek üzerine çalışmaktadır.",
        image: (
          <NSLearnWithUsDesktopStyle.CurvedCardImage
            src="images/learn-with-us/liderlik-egitim.png"
            alt=""
          />
        ),
      },
      {
        title: "Fikirlerin Doğuş Günü",
        description:
          "Çalışma arkadaşlarımızın Fikir Platformu üzerinden paylaştıkları her türlü yaratıcı fikirlerinden doğan projelerini, tüm çalışanlarla paylaşmalarını sağlayıp bu fikirlerin yaygınlaşması ve yeni fikirlerin doğması amacıyla her yıl Fikirlerin Doğuş Günü etkinliği düzenlenmektedir.",
        image: (
          <NSLearnWithUsDesktopStyle.CurvedCardImage
            src="images/learn-with-us/fikir-dogus.png"
            alt=""
          />
        ),
      },
      {
        title: "Fikirlerin Doğuş Günü Mezuniyet",
        description:
          "“Kolayı varsa, işini zorlaştırma!” sloganıyla düzenlediğimiz Fikirlerin Doğuş Günü Mezuniyet Törenimizde, ödül almaya hak kazanan proje ekipleri ve fikir sahiplerine üst yönetimimiz tarafından ödülleri takdim edilmektedir. Aynı zamanda Yalın Liderlik Eğitim Programını tamamlayan çalışma arkadaşlarımızın Yalın Liderlik Sertifikaları paylaşılmaktadır.",
        image: (
          <NSLearnWithUsDesktopStyle.CurvedCardImage
            src="images/learn-with-us/fikir-mezun.png"
            alt=""
          />
        ),
      },
    ],
    accordionList: {
      isMobile: false,
      title: "Eğitim ve Gelişim Programlarımız",
      itemList: [
        {
          title: "Başlangıç Gelişim Programları",
          points: [
            "E-Oryantasyon Eğitim Programları",
            "Oryantasyon Programları",
            "Fonksiyonel Gelişim Programları",
            "DİM Oryantasyon Programı",
          ],
        },
        {
          title: "Yetkinlik ve Değerler Gelişim Programları",
          points: ["Yetkinlik Gelişim Programları", "DİM Gelişim Programı"],
        },
        {
          title: "Liderlik Gelişim Programları",
          points: [
            "Genel Müdür gelişim programı",
            "Direktör Gelişim Programı",
            "Müdür Gelişim Programı",
            "Yönetici Gelişim Eğitim Programı",
            "Diğer Liderlik Gelişim Programları",
          ],
        },
        {
          title: "Kişisel Gelişim Programları",
          points: [
            "Atölyeler Programları",
            "İç Eğitimler Programı",
            "Konuk Konuşmacı Programları",
            "Paneller",
            "GONK",
            "GOWELL",
            "GOFEST",
          ],
        },
        {
          title: "Destekleyici Gelişim Programları",
          points: [
            "Özel Tasarım Programlar",
            "Paylaşım Toplantıları",
            "Yabancı Dil Gelişim Destek Programı",
            "Diğer Destekleyici Gelişim Programları",
            "Yasal-Zorunlu Programlar",
          ],
        },
        {
          title: "Uzmanlık Gelişim Programları",
          points: [
            "İç Eğitmen Gelişim Programı",
            "İç Mentor Gelişim Programı",
            "Yalın Lideri Yetiştirme Programı",
          ],
        },
        {
          title: "Mesleki Gelişim Programları",
          points: ["Konferans, Seminer, Söyleşi", "Mesleki Eğitimle"],
        },
      ],
    },
    roundedDescriptionCarousel: {
      isMobile: false,
      cardList: [
        {
          isMobile: false,
          title: "Yol Hikayesi Ödülü",
          description:
            "Şirket kıdemi 10 yıl üzeri olan çalışa arkadaşlarımıza verilir.",
          image: "/images/learn-with-us/yol_hikayesi.png",
        },
        {
          isMobile: false,
          title: "Yolu Açanlar Ödülü",
          description:
            "Fikir Platformu’nda paylaşılan süreç iyileştirme projeleri arasından en başarılı bulunan projelere verilir.",
          image: "/images/learn-with-us/yolu_acanlar.png",
        },
        {
          isMobile: false,
          title: "Yol Tutkusu Ödülü",
          description:
            "Yol Tutkusu Ödülü, çalışma arkadaşlarımızın yıl içerisindeki başarılarının anlık olarak ödüllendirilmesidir",
          image: "/images/learn-with-us/yol_tutkusu.png",
        },
      ],
    },
    iconDescriptionList: {
      isMobile: false,
      showOrb: false,
      statsList: [
        {
          title: "",
          description:
            "Dijital yetkinlik ve becerilerin gelişimine yönelik eğitimler",
          icon: "/images/learn-with-us/go-academy.png",
        },
        {
          title: "",
          description:
            "Yaşam kalitesini yükseltmeyi hedefleyen etkinlik ve eğitimler",
          icon: "/images/learn-with-us/go-well.png",
        },
        {
          title: "",
          description:
            "Çalışma arkadaşlarımızın gönüllü olarak yer aldığı sanat etkinlikleri",
          icon: "/images/learn-with-us/bizdeSanat.jpg",
        },
      ],
      mainTitle: "",
    },
  } as NSLearnWithUsType.ILearnWithUsData;
};

function LearnWithUs() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <LearnWithUsMobile learnWithUsData={learnWithUsData(isMobile)} />;
  } else {
    return <LearnWithUsDesktop learnWithUsData={learnWithUsData(isMobile)} />;
  }
}

export default LearnWithUs;
