import { NSCvFormStepStyle } from "./CvFormStep.style";
import { NSCvFormStepType } from "./CvFormStep.types";
import { formSteps } from "../../util/formStep";
import { ReactComponent as Road } from "../../assets/icons/road.svg";
import { ReactComponent as Car } from "../../assets/icons/progress-car.svg";
import { useEffect, useRef, useState } from "react";
export const CvFormStep = ({
  onChangeStep,
  activeStep,
  isMobile,
}: NSCvFormStepType.ICvFormStep) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [carPosition, setCarPosition] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updateCarPosition = () => {
      if (ref.current && scrollContainerRef.current) {
        const stepRect = ref.current.getBoundingClientRect();
        const scrollContainerRect =
          scrollContainerRef.current.getBoundingClientRect();
        setCarPosition(stepRect.left - scrollContainerRect.left - 16);
      }
    };

    updateCarPosition();
    setWrapperWidth(containerRef.current?.clientWidth ?? 0);

    window.addEventListener("resize", updateCarPosition);
    return () => window.removeEventListener("resize", updateCarPosition);
  }, [ref, activeStep, scrollPosition]);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      setScrollPosition(scrollContainerRef.current.scrollLeft);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <NSCvFormStepStyle.Wrapper ref={containerRef}>
      <NSCvFormStepStyle.StepWrapper ref={scrollContainerRef}>
        <NSCvFormStepStyle.Container>
          {formSteps.map((item) => (
            <NSCvFormStepStyle.StepContainer
              onClick={() => onChangeStep(item)}
              key={item.id}
              ref={activeStep.id === item.id ? ref : null}
            >
              <NSCvFormStepStyle.Step
                color={item.color}
                active={activeStep === item}
              >
                <span>
                  {item.title}
                  {item.id !== "diger-alanlar" && (
                    <NSCvFormStepStyle.StepStar
                      color={item.color}
                      active={activeStep === item}
                    >
                      *
                    </NSCvFormStepStyle.StepStar>
                  )}
                </span>

                {item.icon}
              </NSCvFormStepStyle.Step>
            </NSCvFormStepStyle.StepContainer>
          ))}
        </NSCvFormStepStyle.Container>

        <NSCvFormStepStyle.ProgressBar max={5} value={activeStep.order} />
      </NSCvFormStepStyle.StepWrapper>

      <NSCvFormStepStyle.RoadPath>
        <Road />
        <NSCvFormStepStyle.IndicatorContainer
          id={activeStep.id}
          style={{
            transform: isMobile
              ? `translateX(${carPosition}px)`
              : `translateX(${
                  (activeStep.order - 1) * 161 +
                  ((activeStep.order - 1) * (wrapperWidth - 161 * 5)) / 4
                }px)`,
          }}
        >
          <Car />
        </NSCvFormStepStyle.IndicatorContainer>
      </NSCvFormStepStyle.RoadPath>
    </NSCvFormStepStyle.Wrapper>
  );
};
