import useDeviceType from "../../../util/isMobile";
import AccountPageDesktop from "./DesktopView/AccountPageDesktop";
import AccountPageMobile from "./MobileView/AccountPageMobile";
import { footerData, headerAnchorList } from "../../../util/headerFooterData";

const accountData = (isMobile: boolean) => {
  return {
    anchorList: headerAnchorList(isMobile),
    footer: footerData,
    logoClicked: () => {},
  };
};

function AccountPage() {
  const { isMobile } = useDeviceType();

  if (isMobile) {
    return <AccountPageMobile accountData={accountData(isMobile)} />;
  } else {
    return <AccountPageDesktop accountData={accountData(isMobile)} />;
  }
}

export default AccountPage;
